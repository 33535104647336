<template>
  <div v-if="display" ref="root" class="popular-carousel" :data-testid="componentID()">
    <template v-if="visible">
      <div class="container" :class="[{'mobile-scroll' : isMobile}, {'remove-container' : game}]">
        <div class="header">
          <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
            {{ title }}
          </h2>
        </div>
        <div v-if="loading" class="placeholder carousel" :class="{'mobile-scroll' : isMobile}">
          <full-art-placeholder v-for="(p, index) in placeholders" :key="index" class="item"/>
        </div>
        <div v-if="!loading" class="carousel" :class="{'mobile-scroll' : isMobile}">
          <div v-for="article in articles" :key="article.canonicalURL" class="slide">
            <card-image-article :article="article" :show-vertical="true" @click="handleClick"/>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Api from '@/api/api';
import CardImageArticle from '@/components/card-elements/Cards/Image/Article.vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import { useDeviceType, useNiceLoad } from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';

const props = defineProps({
  exclude: {
    type: String,
    default: '',
    required: false,
  },
  game: {
    type: String,
    default: '',
    required: false,
  },
  title: {
    type: String,
    required: false,
    default: 'Popular on Infinite',
  },
});

const { isMobile } = useDeviceType();

const root = ref();
const placeholders = 5;
const articles = ref([]);
const loading = ref(true);
const display = ref(true);

const loader = async () => {
  let res;
  try {
    res = await Api.getTrendingContent({
      bucketSize: 'month', game: props.game, rows: 6, exclude: props.exclude,
    });
  } catch (err) { /* catch and fall through */ }
  articles.value = res?.data?.result || [];
  if (articles.value.length < 3) display.value = false;
  loading.value = false;
};

const handleClick = (article) => {
  if (article.stopImmediatePropagation) {
    return;
  }

  const data = {
    title: article.title,
    productLine: article.vertical,
    format: article?.format || '',
    url: article.canonicalURL,
    source: 'Popular Carousel',
    author: article.authorName,
  };

  if (article.date || article.dateTime) {
    try {
      data.datePublished = new Date(article.dateTime || article.date).toISOString();
    } catch (_) { /* Just in case */ }
  }

  amplitudeEvent('infinite', 'infiniteArticleClicked', data);
};

const { visible } = useNiceLoad({
  callback: loader,
  rootElement: root,
});
</script>

<style lang="scss" scoped>
.popular-carousel {
  .header {
    padding: 0 0 $martech-spacer-2 $martech-spacer-3;

    @include breakpoint(1200) {
      padding: 0 0 $martech-spacer-2 0;
    }
  }

  .container {
    &.mobile-scroll {
      padding: 0;
    }
  }

  .placeholder {
    height: 100%;
    display: flex;
    padding: 0;
    width: 100%;

    @include breakpoint(1200) {
      height: 280px;
    }

    .item {
      padding-right: $martech-spacer-3;
      padding-left: $martech-spacer-3;

      @include breakpoint(1200) {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: $martech-spacer-3;

        @include breakpoint(1200) {
          padding-right: 0;
        }
      }

      :deep(.placeholder) {
        width: 150px;

        @include breakpoint(1200) {
          width: 100%;
        }
      }
    }
  }

  .carousel {
    display: inline-flex;
    width: 100%;

    &.mobile-scroll {
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      width: 100vw;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      :deep(.content-wrapper) {
        width: 150px;
        scroll-snap-align: center;
      }

      .slide {
        height: 200px;
        &:first-of-type {
          padding-left: $martech-spacer-3;
        }

        &:last-of-type {
          padding-right: $martech-spacer-3;
        }
      }
    }

    .slide {
      width: 100%;
      height: 280px;
      margin-right: $martech-spacer-2;

      @include breakpoint(1200) {
        margin-right: $martech-spacer-3;

        :deep(.content-wrapper) {
          height: 280px;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
