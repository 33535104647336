<template>
  <div v-if="articleList.length > 0" class="list-view" :data-testid="componentID()">
    <list-card
      v-for="article in articleList"
      :key="article.uuid || article.canonicalURL"
      :article-data="article"
      :show-vertical-link="showVerticalLinks"
      :show-overlay="showCardOverlay"
      :on-click="onClick"/>
  </div>
</template>

<script>
import ListCard from '@/components/card-elements/ListCard.vue';

export default {
  name: 'list-view',
  components: {
    ListCard,
  },
  props: {
    articles: {
      type: Array,
      required: true,
      default: () => [],
    },
    featured: {
      type: Array,
      required: false,
      default: () => [],
    },
    filter: {
      type: Function,
      required: false,
      default: article => true,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
    showVerticalLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortable: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCardOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    filteredFeatured() {
      return this.featured;
    },
    articleList() {
      // Filter the articles list to exclude anything in the featured list.
      const articles = this.articles.filter((article) => {
        let found = false;
        this.featured.forEach((feat) => { found = found || article.canonicalURL === feat.canonicalURL; });
        return !found;
      });

      return articles;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-view {
  width: 100%;

  :deep(.list-item:nth-child(even)) {
    background-color: $martech-surface;
  }
  :deep(.list-item:nth-child(odd)) {
    background-color: $martech-surface-alt;
  }
}
</style>
