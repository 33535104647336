import {
  PageRequestID,
  FleshAndBloodHelpers as fab,
  StringHelper as strings,
  Auth
} from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';
import delve from 'dlv';
import { createRouter, createWebHistory } from 'vue-router';
import Api from './api/api';
import verticalHelpers from './lib/verticals';
import verticalFeeds from './api/verticals';
import flags from './config/views/landing-pages/decks';
import ArticleReadPage from './views/ArticleReadPage.vue';
import HomePage from './views/HomePage.vue';

// A helper function for use in beforeEnter hooks to check if a user has to proper role
let canAccessCache;
const canAccess = async (role) => {
  if (canAccessCache === undefined) {
    canAccessCache = (await Auth.getUserData())?.roles || [];
  }

  return canAccessCache.includes(role);
};

const magicDecksFormats = [];
[ 'Brawl', 'Commander', 'Historic', 'Legacy', 'Modern', 'Pauper', 'Pioneer', 'Standard', 'Vintage', ].forEach((format) => {
  magicDecksFormats.push({
    path: `decks/format/${encodeURIComponent(format.toLowerCase().replaceAll(' ', '-'))}`,
    name: `Magic: The Gathering ${format} Decks`,
    meta: {
      showMainNavigation: true, social: 'magic', gtmName: 'Magic', showVerticalLink: true, vertical: 'magic',
    },
    props: {
      contentType: 'deck',
      verticalName: 'magic',
      format,
      flags: [ 'Pioneer', 'Standard', 'Modern' ].includes(format) ? flags.fixed : flags.decks,
      loader: verticalFeeds.DeckFeedLoader({
        game: 'magic',
        format: format.toLowerCase(),
        latest: true,
        isAdmin: [ 'Standard', 'Modern', 'Pioneer' ].includes(format),
        testDecks: false,
      }),
    },
    component: () => import('./views/landing-pages/Format.vue'),
  });
});

const magicEventsFormats = [];
[ 'Historic', 'Legacy', 'Modern', 'Pauper', 'Pioneer', 'Standard', ].forEach((format) => {
  magicEventsFormats.push({
    path: `events/format/${encodeURIComponent(format.toLowerCase())}`,
    name: `Magic ${format} Events`,
    meta: {
      showMainNavigation: true, social: 'magic', gtmName: 'Magic', showVerticalLink: true, vertical: 'magic',
    },
    props: {
      contentType: 'event',
      verticalName: 'magic',
      format,
      flags: flags.events,
      loader: verticalFeeds.EventsFeedLoader({
        game: 'magic',
        format: format.toLowerCase(),
        isAdmin: [ 'Standard', 'Modern', 'Pioneer' ].includes(format),
        sort: 'created',
        order: 'desc',
      }),
    },
    component: () => import('./views/landing-pages/Format.vue'),
  });
});

const magicArticlesFormats = [];
[ 'Brawl', 'Commander', 'Freeform', 'Historic', 'Legacy', 'Limited', 'Modern', 'Pauper', 'Pioneer', 'Premodern', 'Standard', 'Vintage', '93/94', ].forEach((format) => {
  magicArticlesFormats.push({
    path: `articles/format/${encodeURIComponent(format.toLowerCase().replaceAll(' ', '-'))}`,
    name: `Magic: The Gathering ${format} Articles`,
    meta: {
      showMainNavigation: true, showVerticalLink: true, vertical: 'magic',
    },
    props: {
      verticalName: 'magic',
      format: format.toLowerCase(),
      root: false,
      contentType: [ 'Article', 'Video', 'Podcast' ],
    },
    component: () => import('./views/landing-pages/VerticalLanding.vue'),
  });
});

const channelfireballFormatsList = [ 'Legacy', 'Limited', 'Modern', 'Pauper', 'Pioneer', 'Standard', 'Vintage', ];
const channelfireballArticlesFormats = [];
channelfireballFormatsList.forEach((format) => {
  channelfireballArticlesFormats.push({
    path: `channelfireball/articles/format/${encodeURIComponent(format.toLowerCase().replaceAll(' ', '-'))}`,
    name: `ChannelFireball ${format} Articles`,
    meta: {
      showMainNavigation: true, showVerticalLink: true, vertical: 'magic',
    },
    props: {
      verticalName: 'magic',
      format: format.toLowerCase(),
      root: false,
      contentType: [ 'Article', 'Video', 'Podcast' ],
      tag: 'channelfireball',
    },
    component: () => import('./views/landing-pages/VerticalLanding.vue'),
  });
});

const yugiohFormatsList = [ 'Advanced', 'Common Charity', 'Dragon Ruler Format', 'Edison Format', 'Goat Format', 'Master Duel', 'Speed Duel', 'Traditional', ];

const yugiohDecksFormats = [];
yugiohFormatsList.forEach((format) => {
  const route = {
    path: `decks/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    name: `Yu-Gi-Oh! ${format} Decks`,
    meta: {
      showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', showVerticalLink: true, vertical: 'yugioh',
    },
    props: {
      contentType: 'deck',
      verticalName: 'yugioh',
      format,
      flags: [ 'Advanced' ].includes(format) ? flags.fixed : flags.decks,
      loader: verticalFeeds.DeckFeedLoader({
        game: 'yugioh',
        format: format.toLowerCase(),
        latest: true,
        testDecks: [ 'Advanced' ].includes(format) ? false : null,
      }),
    },
    component: () => import('./views/landing-pages/Format.vue'),
  };
  yugiohDecksFormats.push(route);
});

const yugiohEventsFormats = [];
yugiohFormatsList.forEach((format) => {
  yugiohEventsFormats.push({
    path: `events/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    name: `Yu-Gi-Oh! ${format} Events`,
    meta: {
      showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', showVerticalLink: true, vertical: 'yugioh',
    },
    props: {
      contentType: 'event',
      verticalName: 'yugioh',
      format,
      flags: [ 'Advanced' ].includes(format) ? flags.fixed : flags.decks,
      loader: verticalFeeds.EventsFeedLoader({
        game: 'yugioh',
        format: format.toLowerCase(),
        isAdmin: [ 'Advanced' ].includes(format),
        sort: 'created',
        order: 'desc',
      }),
    },
    component: () => import('./views/landing-pages/Format.vue'),
  });
});

const yugiohArticlesFormats = [];
yugiohFormatsList.forEach((format) => {
  yugiohArticlesFormats.push({
    path: `articles/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    name: `Yu-Gi-Oh! ${format} Articles`,
    meta: {
      showMainNavigation: true, showVerticalLink: true, vertical: 'yugioh',
    },
    props: {
      verticalName: 'yugioh',
      format: format.toLowerCase(),
      root: false,
      contentType: [ 'Article', 'Video', 'Podcast' ],
    },
    component: () => import('./views/landing-pages/VerticalLanding.vue'),
  });
});

const lorcanaFormatsList = [ 'Constructed', 'Limited', ];

const lorcanaDecksFormats = [];
lorcanaFormatsList.forEach((format) => {
  const route = {
    path: `decks/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    name: `Disney Lorcana ${format} Decks`,
    meta: {
      showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', showVerticalLink: true, vertical: 'lorcana',
    },
    props: {
      contentType: 'deck',
      verticalName: 'lorcana',
      format,
      flags: flags.decks,
      loader: verticalFeeds.DeckFeedLoader({
        game: 'lorcana',
        format: format.toLowerCase(),
        latest: true,
      }),
    },
    component: () => import('./views/landing-pages/Format.vue'),
  };
  lorcanaDecksFormats.push(route);
});

const lorcanaEventsFormats = [];
lorcanaFormatsList.forEach((format) => {
  lorcanaEventsFormats.push({
    path: `events/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    name: `Disney Lorcana ${format} Events`,
    meta: {
      showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', showVerticalLink: true, vertical: 'lorcana',
    },
    props: {
      contentType: 'event',
      verticalName: 'lorcana',
      format,
      flags: flags.decks,
      loader: verticalFeeds.EventsFeedLoader({
        game: 'lorcana',
        format: format.toLowerCase(),
        sort: 'created',
        order: 'desc',
      }),
    },
    component: () => import('./views/landing-pages/Format.vue'),
  });
});

const lorcanaArticlesFormats = [];
lorcanaFormatsList.forEach((format) => {
  lorcanaArticlesFormats.push({
    path: `articles/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    name: `Disney Lorcana ${format} Articles`,
    meta: {
      showMainNavigation: true, showVerticalLink: true, vertical: 'lorcana',
    },
    props: {
      verticalName: 'lorcana',
      format: format.toLowerCase(),
      root: false,
      contentType: [ 'Article', 'Video', 'Podcast' ],
    },
    component: () => import('./views/landing-pages/VerticalLanding.vue'),
  });
});

const lorcanaDecksRedirects = [];
lorcanaFormatsList.forEach((format) => {
  lorcanaDecksRedirects.push({
    path: `decks/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    redirect: `/disney-lorcana/decks/format/${format}`,
  });
});

const lorcanaEventsRedirects = [];
lorcanaFormatsList.forEach((format) => {
  lorcanaEventsRedirects.push({
    path: `events/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    redirect: `/disney-lorcana/events/format/${format}`,
  });
});

const lorcanaArticlesRedirects = [];
lorcanaFormatsList.forEach((format) => {
  lorcanaArticlesRedirects.push({
    path: `articles/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    redirect: `/disney-lorcana/articles/format/${format}`,
  });
});

const fleshAndBloodFormatsList = Object.values(fab.getFormats()).map(f => strings.titleCase(f));

const fleshAndBloodArticlesFormats = [];
fleshAndBloodFormatsList.forEach((format) => {
  fleshAndBloodArticlesFormats.push({
    path: `articles/format/${format.toLowerCase().replaceAll(' ', '-')}`,
    name: `Flesh and Blood ${format} Articles`,
    meta: {
      showMainNavigation: true, showVerticalLink: true, vertical: 'flesh and blood',
    },
    props: {
      verticalName: 'flesh and blood',
      format: format.toLowerCase(),
      root: false,
      contentType: [ 'Article', 'Video', 'Podcast' ],
    },
    component: () => import('./views/landing-pages/VerticalLanding.vue'),
  });
});

const moreGamesVerticalsList = verticalHelpers.moreGames;
const moreGamesVerticalLandingArticles = await verticalHelpers.getVerticals().then(response => (
  response
    .filter(v => moreGamesVerticalsList.includes(v.verticalName))
    .map(v => (
      {
        path: v.urlName,
        name: `${v.displayName} Articles`,
        meta: {
          showMainNavigation: true, decks: false, vertical: v.verticalName,
        },
        props: {
          verticalName: v.verticalName,
          showVerticalLink: true,
          root: false,
        },
        component: () => import('./views/landing-pages/VerticalLanding.vue'),
      }
    ))
));

const compare = (obj1, obj2) => (
  Object.keys(obj1).length === Object.keys(obj2).length
    && Object.keys(obj1).every(key => (
      obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
    ))
);

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    // If we are at the same path with the same query params and the only thing that has changed is the hash then do not scroll
    if (to.path === from.path && to.hash !== from.hash && compare(to.params, from.params)) {
      return {};
    }

    // If the savePosition is a number, scroll to that position
    if (typeof to.params.savePosition === 'number') {
      return { top: to.params.savePosition, left: 0 };
    }

    // if the returned position is falsy or an empty object it will retain current scroll position.
    if (to.params.savePosition) {
      return {};
    }

    // sets a delay for the page to scroll back to the top, allowing the pages data to fully load and stop pages from getting stuck on a component
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 });
      }, 150);
    });
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
      meta: { showMainNavigation: true },
    },
    {
      path: '/author/:name/:id?',
      name: 'Authors By Id',
      component: () => import('./views/landing-pages/AuthorLandingRouterView.vue'),
      meta: { showMainNavigation: true },
      children: [
        {
          path: '/author/:name',
          name: 'Authors',
          meta: { showMainNavigation: true },
          component: () => import('./views/landing-pages/AuthorLanding.vue'),
        },
        {
          path: '/author/:name/:id?',
          redirect: { name: 'Authors' },
        },
        {
          path: '/author/:name/:id/articles',
          redirect: { name: 'Authors' },
        },
        {
          path: '/author/:name/articles',
          redirect: { name: 'Authors' },
        },
        {
          path: '/author/:name/:id/decks',
          redirect: { name: 'Author Decks' },
        },
        {
          path: '/author/:name/decks',
          name: 'Author Decks',
          meta: { showMainNavigation: true },
          component: () => import('./views/landing-pages/AuthorLanding.vue'),
          props: { view: 'decks', contentType: 'Deck' },
        },
      ],
    },
    {
      path: '/article/:title/:id',
      component: ArticleReadPage,
      meta: { showMainNavigation: true, articlePage: true, customPageTrack: true },
    },
    {
      path: '/search',
      component: () => import('./views/Search/Results.vue'),
      meta: { showMainNavigation: true, searchPage: true },
    },
    {
      path: '/account/we-will-miss-you',
      name: 'account',
      meta: { showMainNavigation: true, hideSubsPromo: true },
      component: () => import('@/components/subscriptions/account/SubscriptionCancelConfirm.vue'),
      beforeEnter: (to, from, next) => {
        // Prevent external entry directly into the payments pages
        if (from.name === null) {
          next('/account/manage-subscription');
        } else next();
      },
    },
    {
      path: '/submit-deck',
      name: 'submitDeck',
      meta: { showMainNavigation: true, hideSubsPromo: true },
      component: () => import('./views/decks/SubmitDeck.vue'),
      beforeEnter: async (to, from, next) => {
        if (await canAccess('affiliateDeckCreation')) {
          next();
        } else {
          next('/');
        }
      },
    },
    {
      path: '/manage-decks',
      name: 'manageDecks',
      meta: { showMainNavigation: true, hideSubsPromo: true },
      component: () => import('./views/decks/ManageDecks.vue'),
      beforeEnter: async (to, from, next) => {
        if (await canAccess('affiliateDeckCreation')) {
          next();
        } else {
          next('/');
        }
      },
    },
    {
      path: '/magic-the-gathering/deck/:name?/:id',
      name: 'Magic: The Gathering Deck',
      meta: {
        showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic', customPageTrack: true,
      },
      props: { verticalName: 'magic', contentType: 'Deck' },
      component: () => import('./views/landing-pages/DeckLanding.vue'),
    },
    {
      path: '/yugioh/deck/:name?/:id',
      name: 'Yu-Gi-Oh! Deck',
      meta: {
        showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', vertical: 'yugioh', customPageTrack: true,
      },
      props: { verticalName: 'yugioh', contentType: 'Deck' },
      component: () => import('./views/landing-pages/DeckLanding.vue'),
    },
    {
      path: '/disney-lorcana/deck/:name?/:id',
      name: 'Disney Lorcana Deck',
      meta: {
        showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana', customPageTrack: true,
      },
      props: { verticalName: 'lorcana', contentType: 'Deck' },
      component: () => import('./views/landing-pages/DeckLanding.vue'),
    },
    {
      path: '/flesh-and-blood/deck/:name?/:id',
      name: 'Flesh and Blood Deck',
      meta: {
        showMainNavigation: true, social: 'flesh and blood', gtmName: 'Flesh and Blood', vertical: 'flesh and blood', customPageTrack: true,
      },
      props: { verticalName: 'flesh and blood', contentType: 'Deck' },
      component: () => import('./views/landing-pages/DeckLanding.vue'),
    },
    {
      path: '/pokemon/deck/:name?/:id',
      name: 'Pokemon Deck',
      meta: {
        showMainNavigation: true, social: 'pokémon', gtmName: 'Pokémon', vertical: 'pokémon', customPageTrack: true,
      },
      props: { verticalName: 'pokemon', contentType: 'Deck' },
      component: () => import('./views/landing-pages/DeckLanding.vue'),
    },
    {
      path: '/magic-the-gathering',
      meta: {
        showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
      },
      component: () => import('./views/landing-pages/VerticalLandingRouterView.vue'),
      props: { verticalName: 'magic' },
      children: [
        {
          path: '/magic-the-gathering',
          name: 'Magic: The Gathering',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', decks: false, vertical: 'magic',
          },
          props: { verticalName: 'magic', root: true, requiredVerticals: { magic: 4 } },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'articles',
          name: 'Magic: The Gathering Articles',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', decks: false, vertical: 'magic',
          },
          props: { verticalName: 'magic', contentType: [ 'Article', 'Video', 'Podcast' ] },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'commander-bestiary',
          component: () => import('./views/landing-pages/TagLanding.vue'),
          name: 'Commander Bestiary',
          props: { series: 'Commander Bestiary', root: true, vertical: 'magic' },
          meta: { showMainNavigation: true },
        },
        {
          path: 'channelfireball/articles',
          name: 'ChannelFireball Articles',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', decks: false, vertical: 'magic',
          },
          props: { verticalName: 'magic', contentType: [ 'Article', 'Video', 'Podcast' ], tag: 'channelfireball' },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'channelfireball',
          component: () => import('./views/landing-pages/ChannelFireball.vue'),
          name: 'ChannelFireball',
          props: { series: 'ChannelFireball', root: true, vertical: 'magic' },
          meta: { showMainNavigation: true },
        },
        {
          path: 'decks',
          name: 'Magic: The Gathering Decks',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: {
            verticalName: 'magic', flags: flags.default, loader: 'events',
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        // Deck route redirect to /decks
        {
          path: 'deck',
          redirect: 'decks',
        },
        {
          path: 'decks/format',
          name: 'Magic: The Gathering Formats',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: {
            verticalName: 'magic', flags: flags.decks,
          },
          component: () => import('./views/landing-pages/Formats.vue'),
        },
        // Format route redirect to /format
        {
          path: 'decks/formats',
          redirect: 'decks/format',
        },
        {
          path: 'events',
          name: 'Magic: The Gathering Tournaments',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: {
            verticalName: 'magic', events: true, loader: 'events', flags: flags.events,
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        // Event route redirect to /events
        {
          path: 'decks/event',
          redirect: { name: 'Magic: The Gathering Tournaments' },
        },
        {
          path: 'decks/list',
          name: 'Magic: The Gathering Decks List',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: {
            verticalName: 'magic', flags: flags.default, loader: 'decks',
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'decks/player/:id/',
          name: 'Magic: The Gathering Player Decks',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', vertical: 'magic',
          },
          props: { verticalName: 'magic', loader: 'decks', flags: flags.player },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'events/event/:id/',
          name: 'Magic: The Gathering Event Decks',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', decks: false, vertical: 'magic', customPageTrack: true,
          },
          props: { verticalName: 'magic' },
          component: () => import('./views/landing-pages/Event.vue'),
        },
        {
          path: 'decks/event/:id',
          redirect: to => ({ path: `/magic-the-gathering/events/event/${encodeURIComponent(to.params.id)}` }),
        },
        {
          path: 'decks/event/format',
          name: 'Magic: The Gathering Events',
          meta: {
            showMainNavigation: true, social: 'magic', gtmName: 'Magic', decks: false, vertical: 'magic',
          },
          props: { contentType: 'event', verticalName: 'magic', loader: 'events' },
          component: () => import('./views/landing-pages/Format.vue'),
        },
        {
          path: 'decks/advanced-search',
          name: 'Magic: The Gathering Advanced Deck Search',
          component: () => import('./views/Search/AdvancedDeck.vue'),
          meta: { showMainNavigation: true },
          props: { game: 'magic' },
        },
        ...magicDecksFormats,
        ...magicEventsFormats,
        { path: 'articles/format', component: () => import('./views/PageNotFound.vue'), meta: { showMainNavigation: true } },
        ...magicArticlesFormats,
        ...channelfireballArticlesFormats
      ],
    },
    {
      path: '/yugioh',
      meta: {
        showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', vertical: 'yugioh',
      },
      component: () => import('./views/landing-pages/VerticalLandingRouterView.vue'),
      props: { verticalName: 'yugioh' },
      children: [
        {
          path: '/yugioh',
          name: 'Yu-Gi-Oh!',
          meta: {
            showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', decks: false, vertical: 'yugioh',
          },
          props: { verticalName: 'yugioh', root: true, requiredVerticals: { yugioh: 4 } },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'articles',
          name: 'Yu-Gi-Oh! Articles',
          meta: {
            showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', decks: false, vertical: 'yugioh',
          },
          props: { verticalName: 'yugioh', contentType: [ 'Article', 'Video', 'Podcast' ] },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'decks',
          name: 'Yu-Gi-Oh Decks',
          meta: {
            showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', vertical: 'yugioh',
          },
          props: {
            verticalName: 'yugioh', flags: flags.default, loader: 'events',
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        // Deck route redirect to /decks
        {
          path: 'deck',
          redirect: 'decks',
        },
        {
          path: 'decks/format',
          name: 'Yu-Gi-Oh Formats',
          meta: {
            showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', vertical: 'yugioh',
          },
          props: {
            verticalName: 'yugioh', flags: flags.decks,
          },
          component: () => import('./views/landing-pages/Formats.vue'),
        },
        // Format route redirect to /format
        {
          path: 'decks/formats',
          redirect: 'decks/format',
        },
        {
          path: 'events',
          name: 'Yu-Gi-Oh Events',
          meta: {
            showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', vertical: 'yugioh',
          },
          props: {
            verticalName: 'yugioh', events: true, loader: 'events', flags: flags.events,
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'decks/event',
          redirect: { name: 'Yu-Gi-Oh Events' },
        },
        {
          path: 'decks/list',
          name: 'Yu-Gi-Oh Decks List',
          meta: {
            showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', vertical: 'yugioh',
          },
          props: {
            verticalName: 'yugioh',
            flags: flags.default,
            loader: verticalFeeds.DeckFeedLoader({
              game: 'yugioh', format: '', latest: true,
            }),
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'decks/player/:id/',
          name: 'Yu-Gi-Oh Player Decks',
          meta: {
            showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', vertical: 'yugioh',
          },
          props: { verticalName: 'yugioh', loader: 'decks', flags: flags.player },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'events/event/:id/',
          name: 'Yu-Gi-Oh Player Event Decks',
          meta: {
            showMainNavigation: true, social: 'yugioh', gtmName: 'YuGiOh', vertical: 'yugioh', customPageTrack: true,
          },
          props: { verticalName: 'yugioh' },
          component: () => import('./views/landing-pages/Event.vue'),
        },
        {
          path: 'decks/event/:id',
          redirect: to => ({ path: `/yugioh/events/event/${encodeURIComponent(to.params.id)}` }),
        },
        {
          path: 'decks/advanced-search',
          name: 'Yu-Gi-Oh Advanced Deck Search',
          component: () => import('./views/Search/AdvancedDeck.vue'),
          meta: { showMainNavigation: true },
          props: { game: 'yugioh' },
        },
        ...yugiohDecksFormats,
        ...yugiohEventsFormats,
        { path: 'articles/format', component: () => import('./views/PageNotFound.vue'), meta: { showMainNavigation: true } },
        ...yugiohArticlesFormats
      ],
    },
    {
      path: '/pokemon',
      meta: {
        showMainNavigation: true, vertical: 'pokémon',
      },
      component: () => import('./views/landing-pages/VerticalLandingRouterView.vue'),
      props: { verticalName: 'pokemon' },
      children: [
        {
          path: '/pokemon',
          name: 'Pokémon',
          meta: {
            showMainNavigation: true, decks: false, vertical: 'Pokémon',
          },
          props: { verticalName: 'pokemon', root: true, requiredVerticals: { pokemon: 4 } },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'articles',
          name: 'Pokémon Articles',
          meta: {
            showMainNavigation: true, decks: false, vertical: 'Pokémon',
          },
          props: { verticalName: 'pokemon', contentType: [ 'Article', 'Video', 'Podcast' ] },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'events/event/:id/',
          name: 'Pokemon Event Decks',
          meta: {
            showMainNavigation: true, social: 'Pokémon', gtmName: 'Pokémon', decks: false, vertical: 'pokemon', customPageTrack: true,
          },
          props: { verticalName: 'pokemon' },
          component: () => import('./views/landing-pages/Event.vue'),
        },
        {
          path: 'decks/event/:id',
          redirect: to => ({ path: `/pokemon/events/event/${encodeURIComponent(to.params.id)}` }),
        },
        { path: 'articles/format', component: () => import('./views/PageNotFound.vue'), meta: { showMainNavigation: true } },
      ],
    },
    {
      path: '/disney-lorcana',
      meta: {
        showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana',
      },
      component: () => import('./views/landing-pages/VerticalLandingRouterView.vue'),
      props: { verticalName: 'lorcana' },
      children: [
        {
          path: '/disney-lorcana',
          name: 'Disney Lorcana',
          meta: {
            showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', decks: false, vertical: 'lorcana',
          },
          props: { verticalName: 'lorcana', root: true, requiredVerticals: { lorcana: 4 } },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'articles',
          name: 'Disney Lorcana Articles',
          meta: {
            showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', decks: false, vertical: 'lorcana',
          },
          props: { verticalName: 'lorcana', contentType: [ 'Article', 'Video', 'Podcast' ] },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'decks',
          name: 'Disney Lorcana Decks',
          meta: {
            showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana',
          },
          props: {
            verticalName: 'lorcana', flags: flags.default, loader: 'events',
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        // Deck route redirect to /decks
        {
          path: 'deck',
          redirect: 'decks',
        },
        {
          path: 'decks/format',
          name: 'Disney Lorcana Formats',
          meta: {
            showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana',
          },
          props: {
            verticalName: 'lorcana', flags: flags.decks,
          },
          component: () => import('./views/landing-pages/Formats.vue'),
        },
        // Format route redirect to /format
        {
          path: 'decks/formats',
          redirect: 'decks/format',
        },
        {
          path: 'events',
          name: 'Disney Lorcana Events',
          meta: {
            showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana',
          },
          props: {
            verticalName: 'lorcana', events: true, loader: 'events', flags: flags.events,
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'decks/event',
          redirect: { name: 'Disney Lorcana Events' },
        },
        {
          path: 'decks/list',
          name: 'Disney Lorcana Decks List',
          meta: {
            showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana',
          },
          props: {
            verticalName: 'lorcana',
            flags: flags.default,
            loader: verticalFeeds.DeckFeedLoader({
              game: 'lorcana', format: '', latest: true,
            }),
          },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'decks/player/:id/',
          name: 'Disney Lorcana Player Decks',
          meta: {
            showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana',
          },
          props: { verticalName: 'lorcana', loader: 'decks', flags: flags.player },
          component: () => import('./views/landing-pages/Decks.vue'),
        },
        {
          path: 'events/event/:id/',
          name: 'Disney Lorcana Player Event Decks',
          meta: {
            showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana', customPageTrack: true,
          },
          props: { verticalName: 'lorcana' },
          component: () => import('./views/landing-pages/Event.vue'),
        },
        {
          path: 'decks/event/:id',
          redirect: to => ({ path: `/disney-lorcana/events/event/${encodeURIComponent(to.params.id)}` }),
        },
        {
          path: 'decks/advanced-search',
          name: 'Disney Lorcana Advanced Deck Search',
          component: () => import('./views/Search/AdvancedDeck.vue'),
          meta: { showMainNavigation: true },
          props: { game: 'lorcana' },
        },
        ...lorcanaDecksFormats,
        ...lorcanaEventsFormats,
        { path: 'articles/format', component: () => import('./views/PageNotFound.vue'), meta: { showMainNavigation: true } },
        ...lorcanaArticlesFormats
      ],
    },
    {
      path: '/lorcana',
      meta: {
        showMainNavigation: true, social: 'lorcana', gtmName: 'Lorcana', vertical: 'lorcana',
      },
      component: () => import('./views/landing-pages/VerticalLandingRouterView.vue'),
      props: { verticalName: 'lorcana' },
      children: [
        { path: '/lorcana', redirect: { name: 'Disney Lorcana' } },
        { path: 'articles', redirect: { name: 'Disney Lorcana Articles' } },
        { path: 'decks', redirect: { name: 'Disney Lorcana Decks' } },
        { path: 'deck', redirect: { name: 'Disney Lorcana Decks' } },
        { path: 'decks/format', redirect: { name: 'Disney Lorcana Formats' } },
        { path: 'decks/formats', redirect: { name: 'Disney Lorcana Formats' } },
        { path: 'events', redirect: { name: 'Disney Lorcana Events' } },
        { path: 'decks/event', redirect: { name: 'Disney Lorcana Events' } },
        { path: 'decks/list', redirect: { name: 'Disney Lorcana Decks List' } },
        { path: 'deck/:name/:id', redirect: to => ({ path: `/disney-lorcana/deck/${encodeURIComponent(to.params.name)}/${encodeURIComponent(to.params.id)}` }) },
        { path: 'decks/player/:id', redirect: to => ({ path: `/disney-lorcana/decks/player/${encodeURIComponent(to.params.id)}` }) },
        { path: 'events/event/:id', redirect: to => ({ path: `/disney-lorcana/events/event/${encodeURIComponent(to.params.id)}` }) },
        { path: 'decks/event/:id', redirect: to => ({ path: `/disney-lorcana/events/event/${encodeURIComponent(to.params.id)}` }) },
        { path: 'decks/advanced-search', redirect: { name: 'Disney Lorcana Advanced Deck Search' } },
        ...lorcanaDecksRedirects,
        ...lorcanaEventsRedirects,
        ...lorcanaArticlesRedirects
      ],
    },
    {
      path: '/flesh-and-blood',
      meta: {
        showMainNavigation: true, vertical: 'flesh and blood',
      },
      component: () => import('./views/landing-pages/VerticalLandingRouterView.vue'),
      props: { verticalName: 'flesh and blood' },
      children: [
        {
          path: '/flesh-and-blood',
          name: 'Flesh and Blood',
          meta: {
            showMainNavigation: true, decks: false, vertical: 'Flesh and Blood',
          },
          props: { verticalName: 'flesh and blood', root: true, requiredVerticals: { 'flesh and blood': 4 } },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'articles',
          name: 'Flesh and Blood Articles',
          meta: {
            showMainNavigation: true, decks: false, vertical: 'Flesh and Blood',
          },
          props: { verticalName: 'flesh and blood', contentType: [ 'Article', 'Video', 'Podcast' ] },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        {
          path: 'events/event/:id/',
          name: 'Flesh and Blood Player Event Decks',
          meta: {
            showMainNavigation: true, social: 'flesh and blood', gtmName: 'Flesh and Blood', vertical: 'flesh and blood', customPageTrack: true,
          },
          props: { verticalName: 'flesh and blood' },
          component: () => import('./views/landing-pages/Event.vue'),
        },
        {
          path: 'decks/event/:id',
          redirect: to => ({ path: `/flesh-and-blood/events/event/${encodeURIComponent(to.params.id)}` }),
        },
        { path: 'articles/format', component: () => import('./views/PageNotFound.vue'), meta: { showMainNavigation: true } },
        ...fleshAndBloodArticlesFormats,
      ],
    },
    {
      path: '/more-games',
      meta: {
        showMainNavigation: true, vertical: 'More Games',
      },
      component: () => import('./views/landing-pages/VerticalLandingRouterView.vue'),
      children: [
        {
          path: '/more-games',
          name: 'More Games Articles',
          meta: {
            showMainNavigation: true, decks: false, vertical: 'More Games',
          },
          props: {
            verticalName: moreGamesVerticalsList,
            showVerticalLinks: true,
          },
          component: () => import('./views/landing-pages/VerticalLanding.vue'),
        },
        ...moreGamesVerticalLandingArticles,
      ],
    },
    { path: '/infinite', redirect: { name: 'More Games' } },

    {
      path: '/premium/subscription',
      component: () => import('./views/PageNotFound.vue'),
      beforeEnter: () => { window.location.href = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/subscription`; },
    },
    {
      path: '/premium/purchase/payment',
      component: () => import('./views/PageNotFound.vue'),
      beforeEnter: () => { window.location.href = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/subscription/purchase/payment`; },
    },
    {
      path: '/premium/purchase/review',
      component: () => import('./views/PageNotFound.vue'),
      beforeEnter: () => { window.location.href = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/subscription/purchase/review`; },
    },
    {
      path: '/premium/purchase/confirmation',
      component: () => import('./views/PageNotFound.vue'),
      beforeEnter: () => { window.location.href = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/subscription/purchase/confirmation`; },
    },
    {
      path: '/account/subscription/',
      component: () => import('./views/PageNotFound.vue'),
      beforeEnter: () => { window.location.href = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/account/managesubscription`; },
    },
    {
      path: '/account/manage-subscription',
      component: () => import('./views/PageNotFound.vue'),
      beforeEnter: () => { window.location.href = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/account/managesubscription`; },
    },
    {
      path: '/account/cancel-subscription',
      component: () => import('./views/PageNotFound.vue'),
      beforeEnter: () => { window.location.href = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/account/cancelsubscription`; },
    },
    {
      path: '/account/reactivate/confirmation',
      component: () => import('./views/PageNotFound.vue'),
      beforeEnter: () => { window.location.href = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/account/reactivatesubscription`; },
    },

    {
      path: '/decks/player/:id/',
      name: 'All Player Decks',
      meta: {
        showMainNavigation: true,
      },
      props: { loader: 'decks', flags: flags.player },
      component: () => import('./views/landing-pages/Decks.vue'),
    },
    {
      path: '/topics',
      component: () => import('./views/landing-pages/TopicsLanding.vue'),
      name: 'topic landing',
      props: true,
      meta: { showMainNavigation: true, verticalTagsOnly: false },
    },
    {
      path: '/topics/:tag',
      component: () => import('./views/landing-pages/TagLanding.vue'),
      name: 'topic',
      props: true,
      meta: { showMainNavigation: true, verticalTagsOnly: false },
    },
    { path: '/tag/:tag', redirect: to => ({ name: 'topic', params: { tag: to.params.tag } }) },
    {
      path: '/:vertical/articles/:tag',
      component: () => import('./views/landing-pages/TagLanding.vue'),
      name: 'vertical tag',
      props: true,
      meta: { showMainNavigation: true, verticalTagsOnly: true },
    },
    {
      path: '/:vertical/series/:series',
      redirect: to => ({ name: 'series', params: { series: to.params.series } }),
    },
    {
      path: '/series',
      component: () => import('./views/landing-pages/SeriesLanding.vue'),
      name: 'series landing',
      props: true,
      meta: { showMainNavigation: true },
    },
    {
      path: '/series/:series',
      component: () => import('./views/landing-pages/TagLanding.vue'),
      name: 'series',
      props: true,
      meta: { showMainNavigation: true },
      beforeEnter: (to, _, next) => {
        // Prevent commander bestiary navigating to /series and redirect to dedicated route
        if (to.path.toLowerCase() === '/series/commander-bestiary') {
          next('/magic-the-gathering/commander-bestiary');
        } else next();
      },
    },
    {
      name: 'TCGplayer Edge',
      path: '/tcgplayer-edge/:season?',
      component: () => import('./views/landing-pages/cfb/EdgeMasterClasses.vue'),
      meta: { showMainNavigation: true },
      props: true,
    },
    { path: '/uhoh', component: () => import('./views/PageLevelError.vue'), meta: { showMainNavigation: true } },
    { path: '/:pathMatch(.*)*', component: () => import('./views/PageNotFound.vue'), meta: { showMainNavigation: true } },
  ],
});

router.beforeEach((to, from, next) => {
  if (to !== from) {
    // Regenerate the PageRequestID
    window.TCGPageRequestID = PageRequestID.pageRequestID(to.fullPath);
  }
  Api.clearUsedResults();

  let fromName = from.name;
  let fromPath = from.fullPath;

  // If from path is the homepage we need to figure out if we really came from
  // a different page or not due to opening in a new tab/window
  if (delve(from, 'fullPath') === '/') {
    try {
      const referrer = new URL(document.referrer);
      if (delve(referrer, 'hostname') === delve(window, 'location.hostname')) {
        fromPath = referrer.pathname;
        fromName = '';
      }
    } catch (err) { /* catch if document.referrer is empty string */ }
  }

  to.meta.fromPath = fromPath;
  to.meta.fromName = fromName;

  // If customPageTrack is set we expect something within that page to make a page call with custom data
  if (!to.meta.customPageTrack) {
    try {
      const eventName = to.path === '/' ? 'contentHomepageViewed' : 'contentPageViewed';
      amplitudeEvent('infinite', eventName, {
        referrer: document.referrer,
        path: to.fullPath,
        name: to.name,
        fromPath,
        fromName,
        facebookBoost: delve(to, 'query.facebookBoost') === 'true',
        source: delve(to, 'query.source'),
      });
    } catch (err) { /* do nothing */ }
  }

  next();
});

export default router;