<template>
  <div ref="root" class="latest-tag" :data-testid="componentID()">
    <template v-if="visible">
      <div class="header">
        <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          {{ title }}
        </h2>
        <!--Desktop See All Link-->
        <router-link
          v-if="isDesktop && seeMore"
          :to="seeMore"
          :aria-label="`See All ${title}`"
          class="martech-see-all-link">
          See All
        </router-link>
      </div>
      <box-view v-if="articles.length" :articles="articles" :on-click="handleClick" :show-author="true" show-vertical/>
      <div v-else class="placeholder">
        <small-card-placeholder v-for="p in rows" :key="p" class="item"/>
      </div>

      <!--mobile see all link-->
      <router-link
        v-if="isMobile && seeMore"
        :to="seeMore"
        :aria-label="`See All ${title}`"
        class="martech-see-all-link see-all-mobile">
        See All
      </router-link>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import SmallCardPlaceholder from '@/components/placeholder-components/SmallCardPlaceholder.vue';
import BoxView from '@/components/article-list/BoxView.vue';
import { useDelayLoad, useDeviceType } from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';
import { useFetchArticles } from '@/use/fetcher/useFetchArticles';

const props = defineProps({
  tagName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  rows: {
    type: Number,
    default: 3,
  },
  seeMore: {
    type: [ String, Object ],
    default: '',
  },
  placement: {
    type: String,
    default: 'Homepage',
  },
  onClick: {
    type: Function,
    default: (_) => {},
  },
  skipArticlePool: {
    type: Boolean,
    default: false,
  },
});

const root = ref();
const { visible } = useDelayLoad(root);
const { isMobile, isDesktop } = useDeviceType();

const { articles } = useFetchArticles({
  tags: props.tagName,
  count: props.rows,
}, {
  skipPool: props.skipArticlePool,
});

const handleClick = (article) => {
  const data = {
    title: article.title,
    productLine: article.vertical,
    format: article?.format || '',
    url: article.canonicalURL,
    source: 'Latest Tags',
    author: article.authorName,
  };

  if (article.date || article.dateTime) {
    try {
      data.datePublished = new Date(article.dateTime || article.date).toISOString();
    } catch (_) { /* Just in case */ }
  }

  amplitudeEvent('infinite', 'infiniteArticleClicked', data);

  props.onClick(article);
};
</script>

<style lang="scss" scoped>
.latest-tag {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $martech-spacer-2;
  }

  .see-all-mobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: $martech-spacer-3;
  }
}
</style>
