<template>
  <div class="vertical-landing" :data-testid="componentID()">
    <div>
      <div class="wrapper">
        <homepage-hero :key="hero.id" :hero-data="hero"/>
        <div class="vertical-grid" refs="homepage">
          <div class="vertical-grid__background">
            <div class="container">
              <featured-content
                :required-verticals="requiredFeaturedVerticals"
                featured
                show-vertical-link
                :show-date="false"/>
            </div>
            <div v-if="siteConfig('homepage.topNews')" class="articles">
              <tag-carousel title="Top News" tags="news" :show-date="false"/>
            </div>
            <div class="background-divider">
              <div class="container">
                <latest-content
                  title="Latest Content on TCGplayer"
                  :rows="12"
                  :grid-row-count="3"
                  show-vertical-link
                  :required-verticals="requiredLatestVerticals"
                  :show-date="false"/>
              </div>
            </div>
            <div class="container">
              <div class="promo">
                <promos-banner-vertical
                  :vertical="'Homepage'"
                  :domains="siteConfig('global.domain')"
                  :placement="'Homepage'"
                  :promo-size="'leaderboard'"/>
              </div>
              <div v-if="siteConfig('homepage.latestEvents.display')" class="events">
                <latest-events
                  promo-reach="Homepage"
                  placement="Homepage"
                  :rows="7"
                  :included-verticals="siteConfig('homepage.latestEvents.includedVerticals')"/>
              </div>
            </div>
            <div class="background-divider">
              <div class="container">
                <div class="decks">
                  <div v-if="siteConfig('homepage.latestCombinedDecks.display')" class="decks--block-only">
                    <decks-grid
                      :block-grid="true"
                      :featured="true"
                      show-vertical-link
                      :grid-rows-count="siteConfig('homepage.latestCombinedDecks.count')"
                      :item-count="siteConfig('homepage.latestCombinedDecks.itemCount')"
                      title="Featured Decks"
                      random-order/>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="videos">
                <div class="videos--block-one">
                  <video-content
                    :editors-choice="true"
                    :platform="siteConfig('homepage.videoBlock1.platform')"
                    vertical-name="homepage"/>
                </div>
                <div class="videos--block-two">
                  <video-content
                    :editors-choice="true"
                    :platform="siteConfig('homepage.videoBlock2.platform')"
                    vertical-name="homepage"/>
                </div>
              </div>
            </div>
            <div class="background-divider">
              <div class="container">
                <h2 class="martech-sub-heading topics-title">
                  Content in popular topics of interest
                </h2>

                <div class="tags">
                  <div class="tags--block-one">
                    <latest-tags
                      :title="siteConfig('homepage.tags.tag1.title')"
                      :tag-name="siteConfig('homepage.tags.tag1.tag')"
                      :see-more="{ name: 'topic', params: { tag: siteConfig('homepage.tags.tag1.tag') } }"/>
                  </div>
                  <div class="tags--block-two">
                    <latest-tags
                      :title="siteConfig('homepage.tags.tag2.title')"
                      :tag-name="siteConfig('homepage.tags.tag2.tag')"
                      :see-more="{ name: 'topic', params: { tag: siteConfig('homepage.tags.tag2.tag') } }"/>
                  </div>
                  <div class="tags--block-three">
                    <latest-tags
                      :title="siteConfig('homepage.tags.tag3.tag')"
                      :tag-name="siteConfig('homepage.tags.tag3.tag')"
                      :see-more="{ name: 'topic', params: { tag: siteConfig('homepage.tags.tag3.tag') } }"/>
                  </div>
                </div>
                <promoted-topics excluded-topics="['finance', 'budget', 'casual', 'news']"/>
              </div>
            </div>
            <popular-series/>
            <div class="popular">
              <popular-carousel :title="`Popular On ${siteConfig('homepage.popular')}`"/>
            </div>
            <div class="container">
              <div class="promo">
                <promos-banner-vertical
                  :vertical="'Homepage'"
                  :domains="siteConfig('global.domain')"
                  :placement="'Homepage'"
                  :promo-size="'leaderboard'"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from 'vue';
import { get } from '@vueuse/core';
import delve from 'dlv';
import { PromosBannerVertical, useAffinityAlterRequired } from '@tcgplayer/martech-components';
import Api from '@/api/api';
import HomepageHero from '@/components/hero-types/HomepageHero.vue';
import LatestContent from '@/components/article-widgets/LatestContent.vue';
import FeaturedContent from '@/components/article-widgets/FeaturedContent.vue';
import VideoContent from '@/components/video-widgets/Video.vue';
import LatestEvents from '@/components/deck-widgets/LatestEvents.vue';
import PopularCarousel from '@/components/carousel/PopularCarousel.vue';
import DecksGrid from '@/components/deck-widgets/DecksGrid.vue';
import TagCarousel from '@/components/carousel/TagCarousel.vue';
import LatestTags from '@/components/article-widgets/LatestTags.vue';
import PromotedTopics from '@/components/homepage/PromotedTopics.vue';
import PopularSeries from '@/components/homepage/PopularSeries.vue';
import { useHead } from '@vueuse/head';
import { useUser } from '@/use/useUser';

import useSiteConfig from '@/use/useSiteConfig';

const { siteConfig } = useSiteConfig();
const { productLineAffinity } = useUser();

const jsonld = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'TCGplayer Infinite',
  url: 'https://infinite.tcgplayer.com',
  logo: 'https://mp-assets.tcgplayer.com/img/TCGplayer-logo-primary@2x.png',
  sameAs: [
    'https://www.facebook.com/tcgplayer',
    'https://twitter.com/TCGplayer',
    'https://www.youtube.com/user/myTCG',
    'https://www.instagram.com/tcgplayer_com',
    'https://www.linkedin.com/company/tcgplayer/',
    'https://www.tcgplayer.com/'
  ],
});

useHead({
  script: [
    {
      type: 'application/ld+json',
      innerHTML: jsonld,
    },
  ],
});

const requiredFeaturedVerticals = computed(() => {
  const { requiredVerticals } = useAffinityAlterRequired(siteConfig('homepage.featuredRequiredVerticals'), productLineAffinity, 1, 1, 'none');
  return requiredVerticals.value;
});

const requiredLatestVerticals = computed(() => {
  const { requiredVerticals } = useAffinityAlterRequired(siteConfig('homepage.latestRequiredVerticals'), productLineAffinity, 2, 1);
  return requiredVerticals.value;
});

const hero = reactive({});

// We want the affinity if it exists but we also want to start grabbing the default right away so we don't have a delay in showing the hero if the affinity hero does not exist
const tagsPromise = Api.getTags({
  domains: siteConfig('global.domain'),
  classifications: 'product line affinity',
  labels: get(productLineAffinity),
});
const defaultHero = Api.getVerticalHero({ domain: siteConfig('global.domain'), excludeVerticals: [ 'ChannelFireball', 'ChannelFireball Magic', 'TCGplayer Edge', 'Edge', ] });

tagsPromise
  .then((tagsRes) => {
    const tag = tagsRes?.data?.result?.[0]?.id;
    Api.getVerticalHero({ domain: siteConfig('global.domain'), excludeVerticals: [ 'ChannelFireball', 'ChannelFireball Magic', 'TCGplayer Edge', 'Edge', ], tag })
      .then((res) => {
        hero.val = delve(res, 'data.result', {});
        Object.assign(hero, delve(res, 'data.result', {}));
      })
      .catch(() => {
        defaultHero.then((res) => {
          hero.val = delve(res, 'data.result', {});
          Object.assign(hero, delve(res, 'data.result', {}));
        });
      });
  })
  .catch(() => {
    defaultHero.then((res) => {
      hero.val = delve(res, 'data.result', {});
      Object.assign(hero, delve(res, 'data.result', {}));
    });
  });
</script>

<style lang="scss" scoped>
.vertical-grid {
  display: block;
  min-height: 500px;

  @include breakpoint(1024) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    grid-auto-rows: auto;
    max-width: 100%;
  }

  :deep(.popular-carousel),
  :deep(.topnews-carousel) {
    .header {
      padding: 0 0 0.75rem $martech-spacer-3;

      @include breakpoint(1024) {
        padding: 0 0 0.75rem 0;
      }
    }
  }

  :deep(.header) {
    padding: 0 0 0.75rem 0;
  }

  &__background {
    padding-top: $martech-spacer-4;
    background: -webkit-gradient(linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.9)));
  }

  .background-divider {
    background-color: $martech-gray-0;
    padding: $martech-spacer-4 0;
    margin: $martech-spacer-4 0;

    .decks,
    .tags {
      padding-top: 0;
    }
  }

  .topics-title {
    text-align: center;
    margin-bottom: $martech-spacer-4;
  }

  .articles,
  .editors,
  .decks,
  .events,
  .tags {
    padding-top: $martech-spacer-3;
    grid-column: span 12;

    @include breakpoint(1024) {
      padding-top: $martech-spacer-4;
    }
  }

  .videos {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include breakpoint(1200) {
      flex-direction: row;
      align-items: flex-end;
    }

    &--block-one {
      width: 100%;
      padding-bottom: $martech-spacer-3;

      @include breakpoint(1200) {
        margin-right: $martech-spacer-2;
        padding-bottom: 0;
        width: 50%;
      }
    }

    &--block-two {
      width: 100%;

      @include breakpoint(1200) {
        margin-left: $martech-spacer-2;
        width: 50%;
      }
    }
  }

  .popular {
    display: flex;
    align-items: center;
    flex-direction: column;

    .popular-carousel {
      width: 100%;
    }

    @include breakpoint(1024) {
      flex-direction: row;
    }

    &--block-one {
      width: 100%;
      margin-bottom: $martech-spacer-4;

      @include breakpoint(1024) {
        margin-bottom: 0;
        margin-right: 12px;
        width: 50%;
      }
    }

    &--block-two {
      width: 100%;

      @include breakpoint(1024) {
        margin-left: 12px;
        width: 50%;
      }
    }
  }

  .video {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include breakpoint(1024) {
      flex-direction: row;
    }

    &--block-one {
      width: 100%;

      @include breakpoint(1024) {
        margin-bottom: 0;
        margin-right: 12px;
        width: 50%;
      }
    }

    &--block-two {
      width: 100%;

      @include breakpoint(1024) {
        margin-left: 12px;
        width: 50%;
      }
    }
  }

  .decks {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include breakpoint(1500) {
      flex-direction: row;
    }

    &--block-only {
      width: 100%;
      margin-bottom: $martech-spacer-4;

      @include breakpoint(1500) {
        margin-bottom: 0;
        margin-right: 12px;
        width: 100%;
      }
    }

    &--block-one {
      width: 100%;
      margin-bottom: $martech-spacer-4;

      @include breakpoint(1500) {
        margin-bottom: 0;
        margin-right: 12px;
        width: 50%;

        &.four-col {
          width: 100%;
        }
      }
    }

    &--block-two {
      width: 100%;

      @include breakpoint(1500) {
        margin-left: 12px;
        width: 50%;
      }
    }
  }

  .tags {
    display: flex;
    flex-direction: column;

    @include breakpoint(1200) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: $martech-spacer-3;
    }

    &--block-one,
    &--block-two,
    &--block-three {
      width: 100%;
      margin-bottom: $martech-spacer-4;

      @include breakpoint(1200) {
        margin-bottom: 0;
      }
    }

    &--block-three {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  .promo {
    grid-column: span 12;
    padding-top: 0;
  }
}

:deep(.series) {
  padding-bottom: $martech-spacer-4;
}
</style>
