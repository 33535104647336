<template>
  <div class="list-item" :data-testid="componentID()">
    <card-condensed-product
      v-if="type === 'card' && articleData.cardData"
      :article-data="articleData"
      :image="articleData.imageURL"
      :link="articleData.canonicalURL"
      :show-vertical-link="showVerticalLink"
      :title="articleData.title"
      :on-click="onClick"/>

    <card-condensed-deck
      v-if="type === 'deck' && articleData.deckData"
      :data="articleData"
      :author-url="{path:`${articleData.authorURL}`}"
      :image="articleData.imageURL"
      :link="{path:`${articleData.canonicalURL}`}"
      :show-vertical-link="showVerticalLink"
      :title="articleData.title"
      :on-click="onClick"
      @click="handleDeckClick"/>

    <card-condensed-event
      v-if="type === 'event'"
      :data="articleData"
      :on-click="onClick"
      @click="handleEventClick"/>

    <promos-banner-vertical v-if="type === 'promo'" :vertical="articleData.vertical" :domains="siteConfig('global.domain')" :placement="articleData.placement" promo-size="leaderboard"/>
  </div>
</template>

<script>
import { ImageAlter, PromosBannerVertical } from '@tcgplayer/martech-components';
import CardCondensedDeck from '@/components/card-elements/Cards/List/CondensedDeck.vue';
import CardCondensedEvent from '@/components/card-elements/Cards/List/CondensedEvent.vue';
import CardCondensedProduct from '@/components/card-elements/Cards/List/CondensedProduct.vue';
import amplitudeEvent from '@tcgplayer/amplitude';

export default {
  name: 'list-card',
  components: {
    PromosBannerVertical,
    CardCondensedDeck,
    CardCondensedEvent,
    CardCondensedProduct,
  },
  mixins: [ ImageAlter ],
  props: {
    articleData: {
      type: Object,
      required: true,
      default: null,
    },
    showVerticalLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
    vertical: {
      type: String,
      required: false,
      default: '',
    },
    placement: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    type() {
      const type = this.articleData?.type || this.articleData?.contentType || 'article';
      return type.toLowerCase();
    },
  },
  methods: {
    handleDeckClick(deck) {
      const data = {
        title: deck.title,
        productLine: deck.vertical,
        format: deck?.deckData?.format || '',
        url: deck.canonicalURL,
        source: 'List Card',
        player: deck?.deckData?.ownerName || '',
      };
      amplitudeEvent('infinite', 'infiniteDeckClicked', data);
    },
    handleEventClick(event) {
      const data = {
        title: event.title,
        productLine: event.vertical,
        format: event.format,
        url: event.canonicalURL,
        source: 'List Card',
      };
      amplitudeEvent('infinite', 'infiniteEventClicked', data);
    },
  },
};
</script>
