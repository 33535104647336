<template>
  <div class="wrapper" :data-testid="componentID()">
    <placeholder-grid v-if="[ 'grid', 'events' ].includes(placeholderView) && loader && waitForInitialLoad" :row-count="rowCount"/>
    <placeholder-list v-if="[ 'event', 'list', 'box' ].includes(placeholderView) && loader && waitForInitialLoad"/>

    <transition slot="articles" name="fade-card-result">
      <card-grid
        v-if="activeView === 'grid'"
        :articles="articles"
        :on-click="onClick"
        :filter="filter"
        :column-count="columnCount"
        :row-count="rowCount"
        :sortable="sortable"
        :allow-author-link="allowAuthorLink"
        :show-card-overlay="showCardOverlay"
        :show-vertical-link="showVerticalLinks"/>
      <list-view
        v-else-if="activeView === 'list'"
        :articles="articles"
        :on-click="onClick"
        :filter="filter"
        :show-vertical-links="showVerticalLinks"
        :sortable="sortable"
        :featured="featured"
        :show-card-overlay="showCardOverlay"
        :vertical="vertical"/>
      <event-grid v-else-if="activeView === 'events'" :articles="articles" :on-click="onClick" :filter="filter" :featured="featured"/>
      <box-view v-else-if="activeView === 'box'" :articles="articles" :on-click="onClick" :filter="filter"/>
      <page-loader v-else-if="!loading && useLoadingSpinner" :loading-text="loadingText"/>
      <no-results-found v-else-if="showNoResults && filteredCount === 0 && !loading" :search="search" :vertical-name="vertical" :format="format" :content-type="noResultsContentType" @clear-filters="$emit('clear-filters')"/>
    </transition>
    <div v-show="paginated || resultButton" class="load-more-articles">
      <div v-if="paginated && articles.length > 0" class="pagination">
        <page-loader v-if="loading"/>
        <pagination v-if="!loading && pageCount > 1" :loading="loading" :page-number="pageNumber" :page-count="pageCount" :page-size="pageSize" @clicked="loadPage"/>
      </div>
      <div v-if="resultButton && articles.length >= pageSize" class="results-button">
        <base-button btn-style="primary" @clicked="$emit('more-results')">
          <span>{{ buttonLabel }}</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import delve from 'dlv';
import { Pagination } from '@tcgplayer/martech-components';
import BoxView from '@/components/article-list/BoxView.vue';
import EventGrid from '@/components/article-list/EventGrid.vue';
import CardGrid from '@/components/article-list/CardGrid.vue';
import ListView from '@/components/article-list/ListView.vue';
import NoResultsFound from '@/components/shared/NoResultsFound.vue';
import PageLoader from '@/components/shared/PageLoader.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import deviceType from '@/mixins/deviceType';
import PlaceholderGrid from '@/components/placeholder-components/PlaceholderGrid.vue';
import PlaceholderList from '@/components/placeholder-components/PlaceholderList.vue';

export default {
  name: 'article-list',
  components: {
    CardGrid,
    BoxView,
    EventGrid,
    ListView,
    Pagination,
    NoResultsFound,
    PageLoader,
    BaseButton,
    PlaceholderGrid,
    PlaceholderList,
  },
  mixins: [ deviceType ],
  props: {
    featuredRows: {
      type: Number,
      required: false,
      default: 3,
    },
    fetch: {
      type: Function,
      required: true,
      default: ({ rows, offset, featured }) => ({
        articles: [],
      }),
    },
    fetchFeatured: {
      type: Boolean,
      required: true,
      default: true,
    },
    filter: {
      type: Function,
      required: false,
      default: article => true,
    },
    loader: {
      type: Boolean,
      required: false,
      default: true,
    },
    sortable: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortBy: {
      type: String,
      required: false,
      default: '',
    },
    noResults: {
      type: Boolean,
      required: false,
      default: true,
    },
    onError: {
      type: Function,
      required: false,
      default: (error) => {},
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
    paginated: {
      type: Boolean,
      required: false,
      default: true,
    },
    resultButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 48,
    },
    rowCount: {
      type: Number,
      required: false,
      default: 16,
    },
    columnCount: {
      type: Number,
      required: false,
      default: 4,
    },
    showVerticalLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowAuthorLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    view: {
      type: String,
      required: false,
      default: '',
    },
    showCardOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterStore: {
      type: String,
      required: false,
      default: 'contentFilter',
    },
    sortStore: {
      type: String,
      required: false,
      default: 'contentSort',
    },
    onContentChange: {
      type: Function,
      required: false,
      default: (contentInfo) => {},
    },
    buttonLabel: {
      type: String,
      required: false,
      default: '',
    },
    allowListView: {
      type: Boolean,
      required: false,
      default: true,
    },
    vertical: {
      type: String,
      required: false,
      default: '',
    },
    promos: {
      type: Object,
      required: false,
      default: () => ({
        every: 0,
        max: 0,
        placement: null,
      }),
    },
    exclude: {
      type: Array,
      required: false,
      default: () => [],
    },
    excludeName: {
      type: String,
      required: false,
      default: 'deckID',
    },
    format: {
      type: String,
      required: false,
      default: '',
    },
    noResultsContentType: {
      type: String,
      required: false,
      default: '',
    },
    gridRowsCount: {
      type: Number,
      default: null,
      required: false,
      note: 'defines a different count for the grid template rows styling other than column count if needed',
    },
    useLoadingSpinner: {
      type: Boolean,
      required: false,
      note: 'Use this boolean to turn on a loading spinner that you can also pass text too.',
    },
    loadingText: {
      type: String,
      required: false,
      default: 'Loading',
    },
    pageMax: {
      type: Number,
      required: false,
      default: 50000,
      note: 'Use to set the maximum number of pages allowed',
    },
  },
  data() {
    return {
      articles: [],
      removed: [],
      featured: [],
      waitForInitialLoad: true,
      loading: true,
      pageNumber: 1,
      total: 0,
      updating: null,
      sort: null,
      order: null,
      showNoResults: false,
      placeholderCount: 8,
    };
  },
  computed: {
    isList() {
      return this.allowListView && this.$store.state[this.filterStore].isList;
    },
    selectedFilter() {
      return this.$store.state[this.filterStore].selectedFilter;
    },
    appliedFormats() {
      return this.$store.state[this.filterStore].appliedFormats;
    },
    selectedSort() {
      return this.sortStore ? this.$store.state[this.sortStore].selectedSort : this.sortBy;
    },
    search() {
      return this.isSearch();
    },
    filteredCount() {
      return this.articles.filter(this.filter).length;
    },
    activeView() {
      if (this.filteredCount <= 0) { return ''; }
      if (this.view) { return this.view; }
      if (this.isList) { return 'list'; }
      return 'grid';
    },
    placeholderView() {
      if (this.view) { return this.view; }
      if (this.isList) { return 'list'; }
      return 'grid';
    },
    offset() {
      return this.pageSize * (this.pageNumber - 1);
    },
    pageCount() {
      return Math.min(Math.ceil(this.total / this.pageSize), this.pageMax);
    },
  },
  watch: {
    selectedFilter(newVal, oldVal) {
      this.getData(true);
    },
    appliedFormats: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.getData(true);
        }
      },
      deep: true,
    },
    selectedSort(newVal, oldVal) {
      this.setSort(newVal);
      this.getData(true);
    },
    exclude: {
      handler(newVal, oldVal) {
        this.updateArticles(newVal);
      },
      deep: true,
    },
    fetch() {
      this.load(0);
    },
  },
  async created() {
    this.setSort(this.selectedSort);
    this.getData(false);
    if (this.noFeatured()) {
      return;
    }

    if (this.fetchFeatured) {
      await this.fetch({
        rows: this.featuredRows, offset: 0, featured: true, overrideSort: this.sort, overrideOrder: this.order,
      }).then((result) => {
        this.featured = result.articles;
      });
    }

    this.showNoResults = this.noResults;
  },
  methods: {
    async getData(resetPagination = true) {
      if (this.updating) {
        window.clearTimeout(this.updating);
        this.updating = null;
      }

      this.updating = window.setTimeout(() => {
        if (resetPagination && delve(this.$route, 'query.p', 0) !== 0) {
          this.pageNumber = 0;
          // Maintain the current query params and reset the page to 0
          const query = Object.assign({}, delve(this.$route, 'query', {}), { p: 0 });
          this.$router.push({ query });
        }

        if (this.paginated && !resetPagination) {
          this.paginatedCreation();
        }
        this.load(this.pageNumber);

        this.updating = null;
      }, 100);
    },
    async paginatedCreation() {
      const { pageNumber } = this.getPosition();
      if (pageNumber > 0) {
        this.pageNumber = pageNumber;
      }
    },
    injectPromos(articles) {
      if (this.promos.every <= 0) return articles;

      for (let i = 0; i < articles.length; i++) {
        if (articles[i].type === 'promo') {
          return articles;
        }
      }

      let count = 0;
      // We inject that there should be a promo, but not what type as the card type will determine that
      for (let i = this.promos.every; i < articles.length; i += this.promos.every) {
        if (this.promos.max > -1 && count > this.promos.max) {
          break;
        }

        articles.splice(i, 0, {
          type: 'promo',
          vertical: this.vertical,
          placement: this.promos.placement,
          contentType: this.promos.contentType,
          promoSize: this.promos.promoSize,
          noPromoAvailable: () => {
            // Only try to remove it 1 time
            if (this.removed.includes(i)) return;

            // If we have removed an item in an earlier position than the current one for removal
            // it will affect the position we need to remove, so we need to offset it based on earlier
            // removals.
            const offset = this.removed.filter(item => item < i).length;
            this.articles.splice(i - offset, 1);
            this.removed.push(i);
          },
        });
        count++;
      }

      return articles;
    },
    async load(pageNumber) {
      this.loading = true;
      await this.fetch({
        rows: this.pageSize, offset: this.offset, featured: null, overrideSort: this.sort, overrideOrder: this.order,
      }).then((result) => {
        // Removes an excluded id from the articles list
        for (let i = result.articles.length - 1; i >= 0; i--) {
          if (this.exclude.includes(delve(result, `articles.${i}.${this.excludeName}`))) {
            result.articles.splice(i, 1);
          }
        }

        this.articles = Array.isArray(result.articles) ? this.injectPromos(result.articles) : [];
        this.total = result.total;
        this.$emit('article-count', this.total);
        this.waitForInitialLoad = false;
        this.loading = false;
        this.showNoResults = false;

        this.onContentChange({
          resultsTotal: this.total,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      }).catch(() => {
        this.articles = [];
        this.loading = false;
        this.waitForInitialLoad = false;
        this.showNoResults = true;
        this.onError();
      });

      if (this.filteredCount === 0) {
        this.articles = [];
        this.loading = false;
        this.waitForInitialLoad = false;
        this.showNoResults = true;
        this.onError({ response: false });
      }
    },
    updateArticles() {
      // If we haven't finished our initial load of articles then there's nothing to do here...and many times another
      // component is finishing first, updating excludes, causing this to get fired before initial load is done.
      if (this.waitForInitialLoad) return;

      for (let i = this.articles.length - 1; i >= 0; i--) {
        if (this.exclude.includes(delve(this, `articles.${i}.${this.excludeName}`))) {
          this.articles.splice(i, 1);
        }
      }

      this.articles = Array.isArray(this.articles) ? this.injectPromos(this.articles) : [];
      this.total = this.articles.length;
      this.waitForInitialLoad = false;
      this.loading = false;
      this.showNoResults = false;

      this.onContentChange({
        resultsTotal: this.total,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });

      if (this.filteredCount === 0) {
        this.articles = [];
        this.loading = false;
        this.waitForInitialLoad = false;
        this.showNoResults = true;
        this.onError({ response: false });
      }
    },
    setSort(newVal) {
      switch (newVal) {
        case 'latest':
          this.order = 'desc';
          this.sort = 'created';
          break;
        case 'marketHigh':
          this.order = 'desc';
          this.sort = 'price';
          break;
        case 'marketLow':
          this.order = 'asc';
          this.sort = 'price';
          break;
        case 'alphabetical':
          this.order = 'asc';
          this.sort = 'alpha';
          break;
        case 'level':
          this.order = 'desc';
          this.sort = 'level';
          break;
        default:
          this.order = null;
          this.sort = null;
      }
    },
    loadPage(n) {
      this.pageNumber = n;
      this.load(this.pageNumber);
    },
    isSearch() {
      return /(^\/search|\/advanced-search)/.test(this.$route.path);
    },
    noFeatured() {
      return /^\/search/.test(this.$route.path) || /.+\/decks\/?/.test(this.$route.path) || /.+\/articles\/?/.test(this.$route.path) || this.featuredRows < 1;
    },
    getPosition() {
      const { p } = this.getQuery();
      if (!p) {
        return { pageNumber: this.pageNumber };
      }

      const pageNumber = parseInt(p) || this.pageNumber;
      return { pageNumber };
    },
    getQuery() {
      const query = window.location.search.substring(1);
      if (query.length < 1) {
        return {};
      }

      const vars = query.split('&');
      const asObj = {};

      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        asObj[pair[0]] = decodeURIComponent(pair[1]);
      }

      return asObj;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper{
  .no-results {
    padding: 0 0 $martech-spacer-7 0;

    @include breakpoint(1024) {
      padding: $martech-spacer-4 0 $martech-spacer-10 0;
    }

    p {
      text-align: center;
    }

    &__bolded-text {
      font-weight: $martech-weight-semibold;
      font-family: $martech-display-semi-condensed;
      text-transform: uppercase;
      font-size: $martech-type-34;
      color: $martech-mint;
      letter-spacing: 0.4px;
    }

    &__regular-text {
      font-size: $martech-type-20;
      letter-spacing: 0.2px;
      line-height: 2rem; // 32px
    }
  }

  .load-more-articles {
    padding-top: $martech-spacer-4;
  }

  .results-button {
    display: flex;
    justify-content: center;
  }
}
</style>
