<template>
  <div ref="navWrap" class="navigation" :data-testid="componentID()">
    <div v-if="alertVisible" ref="alertRef" class="alert-wrap">
      <alert
        type="info"
        :alert-title="alertTitle"
        :alert-text="alertText"
        :button="{buttonURL: termsURL, buttonText: termsText}"/>
    </div>
    <div v-if="kickbackVisible" ref="kickbackRef" class="kickback-wrap">
      <site-alert-notification
        v-for="alert in kickbackAlerts"
        :key="alert.kickbackInfo.kickbackId"
        :is-active="true"
        :alert-message="alert.kickbackInfo.description"
        alert-link="See Details"
        :alert-url="alert.helpUrl"
        :alert-color="'Blue'"
        class="kickback-banner"/>
    </div>

    <nav ref="navHeader" class="nav-header">
      <div class="nav-header__wrapper" :class="[{'subscriptions-nav': $route.meta.changeNav === 'subscriptions',}]">
        <div class="container">
          <div class="nav-header__content">
            <div class="top-level">
              <navigation-logo class="logo"/>
              <search-bar v-if="!isMobile && route.meta.changeNav !== 'subscriptions'"/>
              <div class="top-level__group">
                <div
                  class="account"
                  tabindex="-1"
                  aria-haspopup="true"
                  role="button"
                  data-toggle="collapse"
                  @click="accountOpen = !accountOpen"
                  @mouseleave="closeAccountMenu">
                  <button
                    class="menu-toggle"
                    tabindex="0"
                    type="button"
                    data-target="#account-menu"
                    :aria-expanded="accountOpen"
                    aria-label="account menu">
                    <subscriber-user-icon-new v-if="isSubsMigrationEnabled && (subscriptionStatus === 'active' || subscription.status === 'active')"/>
                    <subscriber-user-icon v-else-if="!isSubsMigrationEnabled && (subscriptionStatus === 'active' || subscription.status === 'active')"/>
                    <user-icon v-else/>
                  </button>
                  <transition name="fade">
                    <account-menu v-if="accountOpen" :user-name="userName" :subscription-status="subscriptionStatus" :loading="!triedUserFetch"/>
                  </transition>
                </div>
                <div :class="{'is-disabled' : route.meta.changeNav === 'subscriptions'}" class="newsletter-button">
                  <subscribe-button
                    icon
                    btn-style="martech-text"
                    btn-size="martech-medium"
                    text="Emails"
                    :menu-open="isNewsVisible"
                    @sub-clicked="toggleNewsDrawer(true)"/>
                </div>
                <button
                  v-if="isMobile && notSubscriptionsLandingPage"
                  class="menu-toggle hamburger-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#nav-menu"
                  :aria-expanded="isVisible"
                  aria-label="main navigation"
                  @click="toggleNavDrawer(true)">
                  <hamburger-icon/>
                </button>
                <base-link
                  v-if="!isMobile && notSubscriptionsLandingPage"
                  :link-url="tcgplayerStoreUrl"
                  :new-window="!isOpenLinkSameTabEnabled"
                  :url-target="featureFlagUrlTarget"
                  class="shop-link"
                  @click="linkClick(tcgplayerStoreUrl, 'Shop TCGplayer')">
                  Shop TCGplayer <div v-if="!isOpenLinkSameTabEnabled" class="martech-icon martech-external-link-icon martech-black" role="img" aria-label="external link"/>
                </base-link>
              </div>
            </div>
            <search-bar v-if="isMobile && route.meta.changeNav !== 'subscriptions'"/>

            <div class="menu-level">
              <div v-show="!isMobile && notSubscriptionsLandingPage" class="dropdown">
                <div v-for="(menu, index) in dropdownItems" :key="'menu'+index" class="dropdown-item">
                  <div
                    v-if="!isMobile || menu.includeMobile"
                    :id="`navigation-menu-${index}`"
                    class="dropdown-toggle martech-text-uppercase"
                    :class="[{'is-active' : menu.open}, {'no-dropdown-menu' : !menu.isDropdownMenu}]"
                    tabindex="0"
                    aria-haspopup="true"
                    role="button"
                    :aria-expanded="menu.open"
                    data-toggle="collapse"
                    :data-target="`#dropdown-menu-${index}`"
                    @mouseenter="toggleDropdown(index)"
                    @mouseleave="closeDropdown(index)"
                    @keypress.enter.space.prevent.stop="toggleDropdown(index)"
                    @keydown.esc.prevent.stop="closeDropdown(index)"
                    @click="mainNavClick(index)">
                    <base-link
                      :link-url="menu.home || '#'"
                      class="home-link"
                      tabindex="-1"
                      @click="linkClick(menu.home || '#', menu.title)">
                      {{ menu.title }}
                    </base-link>
                    <iconography-chevron v-if="menu.isDropdownMenu" class="chevron-down" direction="down"/>
                    <template v-if="menu.isDropdownMenu">
                      <transition name="fade">
                        <div v-show="menu.open" :id="`dropdown-menu-${index}`" ref="dropdownMenu" class="dropdown-menu shadow" :class="{'two-col' : menu.childRoutes}">
                          <div v-if="menu.childRoutes" class="dropdown-menu__wrapper">
                            <ul>
                              <li v-for="(child, i) in menu.childRoutes.filter(t => !['General', 'Infinite', 'Announcements'].includes(t.text))" :key="i" tabstop="0">
                                <base-link :link-url="child.url || '#'" @click="linkClick(child.url, child.text, 'More Games', index)">
                                  {{ child.text }}
                                </base-link>
                              </li>
                            </ul>
                          </div>
                          <div v-else class="dropdown-menu__wrapper">
                            <ul>
                              <li v-if="menu.game" tabstop="0">
                                <base-link :link-url="menu.home || '#'" @click="linkClick(menu.home, `${menu.title} Home`, menu.game, index)">
                                  {{ menu.title }} Content Home
                                </base-link>
                              </li>
                              <li v-if="menu.game" tabstop="0">
                                <base-link :link-url="menu.articles || '#'" @click="linkClick(menu.articles, 'Articles', menu.game, index)">
                                  Articles
                                </base-link>
                              </li>
                              <li v-if="menu.game && menu.bestiary" tabstop="0">
                                <base-link :link-url="menu.bestiary || '#'" @click="linkClick(menu.bestiary, 'Commander Bestiary', menu.game, index)">
                                  Commander Bestiary
                                </base-link>
                              </li>
                              <li v-if="menu.game && menu.channelfireball" tabstop="0">
                                <base-link :link-url="menu.channelfireball || '#'" @click="linkClick(menu.channelfireball, 'ChannelFireball', menu.game, index)">
                                  ChannelFireball
                                </base-link>
                              </li>
                              <li v-if="siteConfig('navigation.premier').includes(menu.game)" tabstop="0">
                                <base-link :link-url="menu.premier || '#'" @click="linkClick(menu.premiers, 'Premier Articles', menu.game, index)">
                                  Premier Articles
                                </base-link>
                              </li>
                              <li v-if="menu.game" tabstop="0">
                                <base-link v-if="['magic', 'yugioh', 'lorcana'].includes(menu.game)" :link-url="menu.decks || '#'" @click="linkClick(menu.decks, 'Decks & Events', menu.game, index)">
                                  Decks & Events
                                </base-link>
                              </li>
                              <li v-if="menu.game" tabstop="0">
                                <base-link v-if="['magic', 'yugioh'].includes(menu.game)" :link-url="menu.search || '#'" @click="linkClick(menu.search, `${menu.title} Deck Search`, menu.game, index)">
                                  {{ menu.title }} Deck Search
                                </base-link>
                              </li>
                              <hr v-if="menu.game">
                              <li v-if="menu.game" tabstop="0">
                                <base-link :new-window="!isOpenLinkSameTabEnabled" :url-target="featureFlagUrlTarget" :link-url="menu.priceGuide" class="price-guide" @click="linkClick(menu.priceGuide, `${menu.title} Price Guide`, menu.game, index)">
                                  <div class="wrapper">
                                    {{ menu.title }} Price Guide
                                  </div>
                                  <div v-if="!isOpenLinkSameTabEnabled" class="martech-icon martech-external-link-icon martech-black" role="img" aria-label="external link"/>
                                </base-link>
                              </li>
                              <li v-if="menu.game" tabstop="0">
                                <base-link :link-url="menu.shop" :new-window="!isOpenLinkSameTabEnabled" :url-target="featureFlagUrlTarget" class="shop" @click="linkClick(menu.shop, `Shop ${menu.title}`, menu.game, index)">
                                  <div class="wrapper">
                                    Shop {{ menu.title }}
                                  </div>
                                  <div v-if="!isOpenLinkSameTabEnabled" class="martech-icon martech-external-link-icon martech-black" role="img" aria-label="external link"/>
                                </base-link>
                              </li>
                              <li v-for="item in menu.links" :key="item" tabstop="0">
                                <base-link
                                  :link-url="item.link"
                                  :url-target="item.externalLink ? '_blank' : ''"
                                  @click="linkClick(item.link, item.linkText, menu.title === 'More' ? 'more' : menu.game, index)">
                                  {{ item.linkText }}
                                </base-link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </transition>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mobile-nav v-if="isMobile" :user-name="userName" :subscription-status="subscriptionStatus"/>
      <vert-sub-nav v-if="verticalType !== ''" :vertical-type="verticalType" :vertical="vertical"/>
    </nav>
    <header-subscribe-drawer/>
  </div>
</template>

<script setup>
import {
  ref, computed, watch
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  get, set, useEventListener, useResizeObserver, useElementSize
} from '@vueuse/core';

import api from '@/api/api';
import useNavigation from '@/use/Navigation';
import useVerticals from '@/use/Verticals';
import { useUser } from '@/use/useUser';
import useLinksSameTab from '@/use/featureFlags/useLinksSameTab';

import NavigationLogo from '@/components/navigation-elements/NavigationLogo.vue';
import HamburgerIcon from '@/components/iconography/HamburgerIcon.vue';
import AccountMenu from '@/components/navigation-elements/AccountMenu.vue';
import SubscriberUserIcon from '@/components/iconography/user-icons/SubscriberUserIcon.vue';
import SubscriberUserIconNew from '@/components/iconography/user-icons/SubscriberUserIconNew.vue';
import SubscribeButton from '@/components/newsletters/SubscribeButton.vue';
import HeaderSubscribeDrawer from '@/components/newsletters/HeaderSubscribeDrawer.vue';
import UserIcon from '@/components/iconography/user-icons/UserIcon.vue';
import VertSubNav from '@/components/navigation-elements/VerticalLandingSubNav.vue';
import SearchBar from '@/components/navigation-elements/SearchBar.vue';
import MobileNav from '@/components/navigation-elements/MobileNavigation.vue';

import {
  Alert, SiteAlertNotification, useDeviceType, Auth, BaseLink, IconographyChevron, useFeature
} from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';

defineProps({
  height: {
    type: Number,
    required: false,
    default: null,
  },
});

const route = useRoute();
const store = useStore();
const { isOptimizelyFeatureEnabled } = useFeature();
const { userKey } = useUser();
const isSubsMigrationEnabled = isOptimizelyFeatureEnabled('mt-subs-migration', { userKey });
const { isOpenLinkSameTabEnabled, featureFlagUrlTarget } = useLinksSameTab();

const { dropdownItems } = useNavigation();
const { verticalType } = useVerticals();
const { isMobile } = useDeviceType();

const triedUserFetch = ref(false);
const subscriptionStatus = ref();
const userName = ref();
const loaded = ref(false);
const accountOpen = ref(false);
const otherClick = ref(false);
const navHeader = ref(null);
const alertRef = ref(null);
const kickbackRef = ref(null);
const dropdownMenu = ref(null);
const navWrap = ref(null);
const navWidth = ref();
const alertTitle = ref();
const alertText = ref();
const termsURL = ref();
const termsText = ref();
const alertVisible = ref(false);
const kickbackAlerts = ref([]);
const kickbackVisible = ref(false);

const emit = defineEmits([ 'update:height' ]);

const tcgplayerStoreUrl = process.env.VUE_APP_TCGPLAYER_ROOT_URL;

const isNewsVisible = computed(() => store.state.sidebar.isNewsVisible);
const isVisible = computed(() => store.state.sidebar.isVisible);
const subscription = computed(() => store.state.subscription.subscription);

const vertical = computed(() => {
  if (get(route)?.path.includes('/more-games')) {
    return get(route)?.path.match(/([^/]*)\/?$/)[1].replaceAll('-', ' ');
  }

  return get(verticalType);
});

const { height: navHeight } = useElementSize(navHeader);
const { height: alertHeight } = useElementSize(alertRef);
const { height: kickbackHeight } = useElementSize(kickbackRef);

watch(
  [ get(route).fullPath, navHeight, alertHeight, kickbackHeight ],
  () => emit('update:height', alertHeight.value + kickbackHeight.value + navHeight.value)
);

const toggleNewsDrawer = (val) => {
  store.dispatch('sidebar/toggleNewsDrawer', val);
};

const toggleNavDrawer = (val) => {
  store.dispatch('sidebar/toggleNavDrawer', val);
};

const alertActive = (val) => {
  store.dispatch('alert/alertActive', val);
};

if (!get(triedUserFetch)) {
  Auth.getUserData().then((userData) => {
    set(triedUserFetch, true);
    set(loaded, userData.authenticated);
    set(userName, userData.userName);
    set(subscriptionStatus, userData.subscriptionStatus);
  });
}

const getAlert = () => {
  api.getAlert()
    .then((response) => {
      const res = response.data.result;
      set(alertTitle, res?.title || null);
      set(alertText, res?.text || null);
      set(termsURL, res?.termsURL || null);
      set(termsText, res?.termsText || null);
      set(alertVisible, true);
      alertActive(true);
    }).catch(() => {
      set(alertVisible, false);
      alertActive(false);
    });
};

getAlert();

const getKickbacks = () => {
  api.getKickbacks()
    .then((response) => {
      set(kickbackAlerts, response.data.results);
      set(kickbackVisible, true);
    }).catch(() => {
      set(kickbackVisible, false);
    });
};

getKickbacks();

const notSubscriptionsLandingPage = computed(() => get(route).meta.changeNav !== 'subscriptions');

const updatePositionOnScroll = () => {
  if (!get(alertVisible)) return;
  // Positions nav bar at top 0px if scroll is 15px from top.
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

  if (get(route).meta.changeNav === 'subscriptions') {
    get(navHeader).style.position = 'relative';
  }

  if (winScroll >= 15) {
    get(navHeader).style.transform = `translateY(-${get(alertRef).offsetHeight + get(kickbackRef).offsetHeight}px)`;
    get(navHeader).style.transition = 'transform .18s cubic-bezier(0.17, 0.17, 0, 1)';
  } else {
    get(navHeader).style.transform = 'translateY(0px)';
  }
};

useEventListener(document, 'scroll', () => {
  updatePositionOnScroll();
});

useResizeObserver(navHeader, (entries) => {
  const entry = entries[0];
  const { width } = entry.contentRect;
  navWidth.value = width;
});

const handleLastDropdownPosition = () => {
  if (get(navWidth) > 1499) {
    get(dropdownMenu)[5].style.left = '0';
    get(dropdownMenu)[5].style.right = 'auto';
  } else if (get(navWidth) <= 1500) {
    get(dropdownMenu)[5].style.right = '0';
    get(dropdownMenu)[5].style.left = 'auto';
  }
};

const toggleDropdown = (i) => {
  // Only allow 1 menu open at a time
  get(dropdownItems).forEach((menu, index) => {
    if (index !== i) menu.open = false;
  });

  get(dropdownItems)[i].open = !get(dropdownItems)[i].open;

  // Set focus so the escape key will work to close the menu
  document.getElementById(`navigation-menu-${i}`).focus();

  handleLastDropdownPosition();
};

function linkClick(linkURL, linkTitle, linkParent, index) {
  set(otherClick, true);
  toggleDropdown(index);
  amplitudeEvent('infinite', 'infiniteNavigationClicked', {
    linkURL,
    linkTitle,
    linkParent,
    location: 'primary',
  });
}

const mainNavClick = (i) => {
  get(dropdownItems)[i].open = false;
  if (!get(otherClick)) linkClick(get(dropdownItems)[i].home, get(dropdownItems)[i].title);
  set(otherClick, false);
};

const closeAccountMenu = () => {
  setTimeout(() => {
    set(accountOpen, false);
  }, 300);
};

const closeDropdown = (i) => {
  get(dropdownItems)[i].open = false;
};

</script>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  top: 0;
  z-index: 2147483641;
  transform: translate3d(0, 0, 0);
  width: 100%;

  .site-alert.notification {
    text-align: center;
  }

  .kickback-banner.site-alert.notification {
    background-color: #1c75bd;
    color: #ffffff;
    -webkit-font-smoothing: auto;
    :deep(a) {
      color: #ffffff;
      text-decoration: underline;
    }
  }

  .nav-header {
    position: relative;
    background: transparent;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;

    &__wrapper {
      background-color: $martech-white;
      width: 100%;
      border-bottom: 1px solid $martech-border;
      padding: $martech-spacer-2 0;

      @include breakpoint(1024) {
        height: 94px;
        padding: $martech-spacer-2 0 0 0;
      }

      &.subscriptions-nav {
        border-bottom: 1px solid $martech-white;
        height: 75px;
        padding: $martech-spacer-2 0;

        .top-level__group {
          justify-content: flex-end;
        }
      }
    }

    &__content {
      width: 100%;

      .top-level {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .navigation-logo {
          @include breakpoint(1024) {
            margin-right: $martech-spacer-4;
          }

          @include breakpoint(1500) {
            margin-right: $martech-spacer-6;
          }
        }

        .search-bar {
          @include breakpoint(1024) {
            margin-right: $martech-spacer-3;
          }

          @include breakpoint(1500) {
            margin-right: $martech-spacer-5;
          }
        }

        &__group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include breakpoint(1024) {
            width: 68%;
          }

          @include breakpoint(1200) {
            width: 52%;
          }

          @include breakpoint(1500) {
            width: 37%;
          }
        }
      }

      .menu-level {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.menu-toggle {
  background: transparent;
  border: 0;
  color: $martech-black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 48px;

  @include breakpoint(1024) {
    margin: 0 $martech-spacer-2;
    width: auto;
  }

  &.search {
    margin: 0;

    svg {
      height: 20px;
      width: 20px;
      margin-top: -4px;
    }
  }

  &.is-disabled {
    display: none;
  }

  &.white-icon {
    color: $martech-white;
    padding-right: 0;
  }

  &:hover {
    color: $martech-gray-100;
  }
}

.hamburger-button {
  display: block;

  @include breakpoint(1200) {
    display: none;
  }
}

.logged-in {
  margin: 0;
  padding-right: $martech-spacer-4;
}

.subs-login-btn {
  margin-right: $martech-spacer-3;
}

.newsletter-button {
  :deep(.subscribe-button) {
    .martech-button {
      border: 0;
    }
  }

  &.is-disabled {
    display: none;
  }

  display: none;

  @media only screen and (min-width: 1025px) {
    display: block;
  }
}

.account {
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;

  button {
    margin: 0;
    padding: 0;
  }
}

.shop-link {
  position: relative;
  top: 2px;

  :deep(a) {
    padding: 0 $martech-spacer-3;
    font-weight: $martech-weight-semibold;
    color: $martech-gray-160;
    font-size: $martech-type-14;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.05s ease-in-out;
    border-bottom: 4px solid $martech-white;
    position: relative;
    line-height: 14px;
    margin: 0;

    .martech-external-link-icon {
      margin-left: $martech-spacer-2;
      max-height: 12px;
      max-width: 12px;
      margin-bottom: 2px;
    }

    &:hover {
      color: $martech-blue;
    }
  }
}

.dropdown {
  display: flex;
  position: relative;
  overflow: visible;
  padding-left: $martech-spacer-2;
  align-items: center;
  height: 40px;

  &-toggle {
    height: 40px;
    padding: 0 $martech-spacer-3;
    font-weight: $martech-weight-semibold;
    letter-spacing: 0.5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.05s ease-in-out;
    border-bottom: 4px solid $martech-white;
    position: relative;
    transform: translate3d(0, 0, 0);
    z-index: 5;

    @media only screen and (min-width: 1025px) {
      padding: 0 $martech-spacer-2;
    }

    @include breakpoint(1200) {
      padding: 0 $martech-spacer-3;
    }

    .chevron-down {
      margin-left: $martech-spacer-2;
      width: 11px;
      height: 6px;
    }

    .home-link {
      :deep(a) {
        color: $martech-gray-160;
        font-size: $martech-type-13;
      }

      @media only screen and (min-width: 1025px) {
        font-size: $martech-type-12;
      }

      @include breakpoint(1200) {
        font-size: $martech-type-13;
      }
    }

    &.is-active {
      border-bottom: 4px solid $martech-blue;
      transform: scaleX(1);

      &.no-dropdown-menu {
        border-bottom: 4px solid $martech-white;
      }

      .home-link {
        :deep(a) {
          color: $martech-blue;
        }
      }

      .chevron-down {
        transition: transform 0.15s ease-in;
        transform: rotateX(180deg);
      }
    }
  }

  &-menu {
    position: absolute;
    z-index: 3;
    background-color: $martech-white;
    top: 41px;
    left: 0;
    width: 262px;
    border-bottom-right-radius: $martech-radius-default;
    border-bottom-left-radius: $martech-radius-default;

    &.two-col {
      width: 450px;

      ul {
        columns: 2;
      }
    }

    &__wrapper {
      position: relative;
      overflow: hidden;

      .symbol {
        position: absolute;
        color: $martech-gray-10;
        right: 0;
        top: 0;
        z-index: 0;
        width: 60%;
        overflow: hidden;
        opacity: 0.85;

        :deep(.magic-symbol) {
          width: 210px;
          right: -10px;
          position: relative;
          top: -50px;
        }

        :deep(.yugioh-symbol) {
          width: 210px;
          right: -10px;
          position: relative;
          top: -30px;
        }

        :deep(.pokemon-symbol) {
          width: 185px;
          right: 0;
          position: relative;
          top: -65px;
        }
      }
    }

    ul {
      list-style: none;
      position: relative;
      z-index: 3;
      padding: $martech-spacer-2 0;
      margin: 0;

      hr {
        margin: $martech-spacer-2 0;
      }

      .shop,
      .price-guide {
        .wrapper {
          display: flex;
          align-items: center;
          width: 100%;
        }
      }

      li {
        margin: 0 4px;
        break-inside: avoid;

       /* adds a zero-width space for screen readers to think its still a list item */
        &::before {
          content: "";
        }

        :deep(a) {
          height: 38px;
          display: flex;
          align-items: center;
          padding: 0 $martech-spacer-2;
          font-size: $martech-type-14;
          letter-spacing: 0.4px;
          color: $martech-gray-160;
          text-transform: capitalize;
          font-weight: $martech-weight-normal;

          .martech-icon {
            margin-right: $martech-spacer-3;
          }

          &:hover {
            background: rgba(207, 229, 255, 0.5);
            border-radius: $martech-radius-default;
          }

          .martech-external-link-icon {
            margin-right: 0;
            height: 100%;
            width: 100%;
            max-width: 13px;
            max-height: 13px;
          }
        }
      }
    }
  }
}
</style>
